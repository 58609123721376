import React from "react";

export default function NotFoundPage() {
  return (
    <>
      <h1> Yikes...The specified Page could not be found</h1>
      <p> sorry....</p>
    </>
  );
}
